import { useEffect, useState } from 'react';
import _ from 'lodash';

import buffs from '../common/pricing/BuffList.json';
import npcs from '../common/pricing/NPCList.json';

const orders = ["asc", "desc"];

const Shop = () => {

    const [tab, setTab] = useState("npcs");
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(9999999999);
    const [sort, setSort] = useState({ field: "Name", order: "asc" });
    const [lastClicked, setLastClicked] = useState("Name");
    const [searchParam, setSearchParam] = useState("");
    const [sortedList, setSortedList] = useState([]);

    useEffect(() => {
        if (tab === "buffs") setSortedList(_.orderBy(buffs?.List, [buff => typeof buff[sort.field] === "string" ? buff[sort.field].toLowerCase() : buff[sort.field]], [sort.order]));
        else setSortedList(_.orderBy(npcs?.List, [npc => typeof npc[sort.field] === "string" ? npc[sort.field].toLowerCase() : npc[sort.field]], [sort.order]));
    }, [sort, tab]);

    const handleSort = (e) => {
        setSort({ field: e.target.name, order: lastClicked === e.target.name ? orders.find(order => order !== sort.order) : "asc" });
        setLastClicked(e.target.name);
    };

    return (
        <>
            <button className={`px-5 my-2 bg-green-400 rounded${tab === "buffs" ? " bg-green-600 text-white" : ""}`} onClick={() => setTab("buffs")}>Buffs</button>
            <br />
            <button className={`px-5 my-2 bg-green-400 rounded${tab === "npcs" ? " bg-green-600 text-white" : ""}`} onClick={() => setTab("npcs")}>NPCs</button>
            <br />
            <label>Price between&nbsp;</label>
            <input className="border border-gray-300 rounded shadow" placeholder="0" type="number" onChange={(e) => setMinPrice(e.target.value)} />
            <label>&nbsp;and&nbsp;</label>
            <input className="border border-gray-300 rounded shadow mb-5" placeholder="9999999999" type="number" onChange={(e) => setMaxPrice(e.target.value)} />
            <br />
            <input className="border border-gray-300 rounded shadow" placeholder="Search" type="text" value={searchParam} onChange={(e) => setSearchParam(e.target.value)} />
            <br />
            <button name="Name" className={`px-5 my-2 bg-green-400 mx-2 rounded${sort.field === "Name" ? " bg-green-600 text-white" : ""}`} onClick={(e) => handleSort(e)}>Sort By Name</button>
            <button name="Price" className={`px-5 my-2 bg-green-400 mx-2 rounded${sort.field === "Price" ? " bg-green-600 text-white" : ""}`} onClick={(e) => handleSort(e)}>Sort By Price</button>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedList?.
                        filter(item => searchParam ? item.Name.toLowerCase().includes(searchParam.toLowerCase()) : item)
                        .filter(item => minPrice ? item.Price > minPrice : item)
                        .filter(item => maxPrice ? item.Price < maxPrice : item)
                        .map((item, index) => {
                            return (
                                <tr key={index}>
                                    {item.Image ? <td><img alt={item.Name} src={item.Image} style={{ height: 25, width: 25 }} /></td> : null}
                                    <td>{item.Name}</td>
                                    <td>{item.Price}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    );
}

export default Shop;